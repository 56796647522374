import {
  Autocomplete,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material"
import { Box } from "@mui/system"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import sports from "../data/sports.json"
import theme from "../theme"
import withTrans from "./withTrans"

const ActivitySelect = ({ onChange, images, t }) => {
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [selectedActivityImg, setSelectedActivityImg] = useState(null)
  const activities = Object.keys(sports.trainings).map(k => {
    return {
      key: k,
      title: t(`activities.sport.label.${k}`),
      pal: sports.trainings[k].pal,
      image: sports.trainings[k].image,
      imageData: images.find(
        n => `${n.name}${n.ext}` === sports.trainings[k].image
      ).childImageSharp.gatsbyImageData,
    }
  })

  const handleActivityChange = (event, value) => {
    setSelectedActivity(value)
    if (value) {
      onChange(value.pal, value.imageData, value.title)
      setSelectedActivityImg(
        images.find(n => `${n.name}${n.ext}` === value?.image).childImageSharp
          .gatsbyImageData
      )
    } else {
      onChange(null, null, "")
      setSelectedActivityImg(null)
    }
  }

  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <Autocomplete
        options={activities}
        fullWidth={false}
        getOptionLabel={option => option.title}
        onChange={handleActivityChange}
        value={selectedActivity}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props} key={option.key}>
            <ListItemAvatar>
              <GatsbyImage
                style={{
                  borderRadius: theme.spacing(1),
                  height: 37,
                  width: 37,
                }}
                alt={option.title}
                image={
                  images.find(n => `${n.name}${n.ext}` === option.image)
                    .childImageSharp.gatsbyImageData
                }
              />
            </ListItemAvatar>
            <ListItemText
              primary={option.title}
              secondary={t("calculator.calories_burned.label.burns_per_hour", {
                calories: Math.round(
                  (60 * (10 * 65 + 6.25 * 170 - 5 * 35 - 161) * option.pal) /
                    1440
                ),
              })}
            />
          </ListItem>
        )}
        sx={{
          px: theme.spacing(6),
          maxWidth: 600,
          mx: "auto",
          mt: theme.spacing(4),
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    sx={{
                      borderRadius: theme.spacing(1),
                      mb: 1,
                    }}
                  >
                    {selectedActivityImg && (
                      <GatsbyImage
                        style={{
                          borderRadius: theme.spacing(1),
                          height: 37,
                          width: 37,
                        }}
                        image={selectedActivityImg}
                        alt={selectedActivity.title}
                      />
                    )}
                  </Box>
                </InputAdornment>
              ),
              endAdornment: null,
            }}
            placeholder={t("calculator.calories_burned.input.search_exercise")}
          />
        )}
      />
    </Box>
  )
}

export default withTrans(ActivitySelect)
