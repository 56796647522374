import styled from "@emotion/styled"
import {
  Alert,
  alpha,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import i18next from "i18next"
import React, { useEffect, useState } from "react"
import PageHeadingIcon from "../assets/images/icons/lightning-green.svg"
import ActivitySelect from "../components/ActivitySelect"
import AdAppCounting from "../components/AdAppCounting"
import CalcInputBox from "../components/CalcInputBox"
import GenderSwitch from "../components/GenderSwitch"
import Layout from "../components/Layout"
import PageHeading from "../components/PageHeading"
import Seo from "../components/Seo"
import Textblock from "../components/Textblock"
import TextInput from "../components/TextInput"
import withTrans from "../components/withTrans"
import theme from "../theme"

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.main,
  disableElevation: true,
  borderColor: theme.palette.success.dark,
  "&.MuiButton-contained": {},
  "&.MuiButton-outlined": {
    border: `1px solid #D0D5DD`,
    backgroundColor: alpha(theme.palette.success.light, 0.1),
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.success.light, 1),
  },
}))

const CalcIdealWeight = ({ data, t }) => {
  const images = data?.allFile?.nodes
  const upMd = useMediaQuery(theme.breakpoints.up("md"))

  // errors
  const [errors, setErrors] = useState([])

  // inputs
  const [weightKg, setWeightKg] = useState("")
  const [weightLb, setWeightLb] = useState("")
  const [unitWeight, setUnitWeight] = useState("metric")
  const [gender, setGender] = useState("")
  const [age, setAge] = useState("")
  const [pal, setPal] = useState(null)
  const [duration, setDuration] = useState("")

  // calculated
  const [isValid, setIsValid] = useState(false)
  const [burnedKcal, setBurnedKcal] = useState("")
  const [burnedkJ, setBurnedkJ] = useState("")

  // ui
  const [showError, setShowError] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [resultImage, setResultImage] = useState(null)
  const [activityName, setActivityName] = useState("")
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    calculate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pal,
    gender,
    age,
    unitWeight,
    weightKg,
    weightLb,
    duration,
    submitted,
    showError,
  ])

  useEffect(() => {
    if (shouldScroll) {
      var anchor = document.querySelector('#result-container');
      if (anchor) {
        setTimeout(() => {
          anchor.scrollIntoView({ behavior: "smooth", block: "start" })
        }, 50)
      }
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  const handleUnitWeightChange = value => {
    setUnitWeight(value)
  }

  const handleGenderChange = value => {
    setGender(value)
    const newErrors = errors.filter(e => e !== "gender")
    setErrors(newErrors)
  }

  const handleActivityChange = (pal, image, act) => {
    setPal(pal)
    setResultImage(image)
    setActivityName(act)
  }

  const handleAgeChange = event => {
    const newAge = parseInt(event.target.value)
    if (Number.isInteger(newAge)) {
      setAge(event.target.value)
      const newErrors = errors.filter(e => e !== "age")
      setErrors(newErrors)
    } else {
      setAge("")
    }
  }

  const handleDurationChange = event => {
    const newDuration = parseInt(event.target.value)
    if (Number.isInteger(newDuration)) {
      setDuration(newDuration)
      const newErrors = errors.filter(e => e !== "duration")
      setErrors(newErrors)
    } else {
      setDuration(0)
    }
  }

  const handleWeightChange = value => {
    const newHeight = parseInt(value)
    if (Number.isInteger(newHeight)) {
      if (unitWeight === "metric") {
        setWeightKg(value)
      } else {
        setWeightLb(value)
      }
      const newErrors = errors.filter(e => e !== "weight")
      setErrors(newErrors)
    } else {
      if (unitWeight === "metric") {
        setWeightKg("")
      } else {
        setWeightLb("")
      }
    }
  }

  const validate = () => {
    const newErrors = []
    if (!pal) {
      newErrors.push("pal")
    }

    if (!gender) {
      newErrors.push("gender")
    }

    if (!age) {
      newErrors.push("age")
    }

    if (unitWeight === "metric") {
      if (!weightKg) {
        newErrors.push("weight")
      }
    }

    if (unitWeight === "imperial") {
      if (!weightLb) {
        newErrors.push("weight")
      }
    }

    if (newErrors.length > 0 && submitted) {
      setShowError(true)
    }

    submitted && setErrors(newErrors)

    if (newErrors.length < 1) {
      setIsValid(true)
      return true
    } else {
      setIsValid(false)
      return false
    }
  }

  const calculate = () => {
    if (!validate()) {
      return false
    }

    if (errors.length > 0) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }

    if (unitWeight === "imperial") {
      let weightKg = weightLb / 2.2046
      setWeightKg(Math.round(weightKg))
    }

    const genderValue = gender === "m" ? 5 : -161

    const res = Math.round(
      (duration * (10 * weightKg + 6.25 * 170 - 5 * age + genderValue) * pal) /
        1440
    )
    setBurnedKcal(res)
    setBurnedkJ(Math.round(res * 4.1868))
  }

  const handleShowResult = () => {
    setSubmitted(true)
    validate()
    setShowError(!isValid)
    setShowResult(true)
    setShouldScroll(true)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleShowResult();
    }
  }

  return (
    <Layout pageName="start" theme={theme}>
      <Container onKeyDown={handleKeyDown}>
        <PageHeading
          title={t("calculator_page.calories_burned.heading")}
          icon={PageHeadingIcon}
        >
          {t("calculator_page.calories_burned.teaser.text", "")}
        </PageHeading>
        <Grid
          container
          sx={{ mt: theme.spacing(4) }}
          rowSpacing={1}
          columnSpacing={{ md: theme.spacing(2) }}
        >
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <CalcInputBox
              error={errors.includes("pal")}
              num={1}
              title={t("calculator.calories_burned.label.enter_exercise")}
            >
              <ActivitySelect images={images} onChange={handleActivityChange} />
            </CalcInputBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <CalcInputBox
              error={errors.includes("duration")}
              num={2}
              title={t("calculator.calories_burned.label.duration")}
            >
              <TextInput
                placeholder="60"
                label={""}
                type="number"
                error={errors.includes("duration")}
                variant="standard"
                value={duration}
                onChange={handleDurationChange}
              />
              <Typography
                variant="body1"
                component="div"
                sx={{
                  display: "inline-block",
                  lineHeight: { xs: "3rem", md: "3.125rem" },
                }}
              >
                {t("calculator.calories_burned.label.minutes")}
              </Typography>
            </CalcInputBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <CalcInputBox
              error={errors.includes("gender")}
              num={3}
              title={t("calculator.general.label.gender")}
            >
              <GenderSwitch
                error={errors.includes("gender")}
                onInput={handleGenderChange}
                value={gender}
              />
            </CalcInputBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <CalcInputBox
              error={errors.includes("age")}
              num={4}
              title={t("calculator.general.label.age")}
            >
              <TextInput
                placeholder="21"
                label={""}
                type="number"
                error={errors.includes("age")}
                variant="standard"
                value={age}
                onChange={handleAgeChange}
              />
              <Typography
                variant="body1"
                component="div"
                sx={{
                  display: "inline-block",
                  lineHeight: { xs: "3rem", md: "3.125rem" },
                }}
              >
                {t("calculator.general.label.years")}
              </Typography>
            </CalcInputBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <CalcInputBox
              error={errors.includes("weight")}
              num={5}
              title={t("calculator.general.label.weight")}
            >
              {unitWeight === "metric" ? (
                <>
                  <TextInput
                    error={errors.includes("weight")}
                    placeholder="60"
                    value={weightKg}
                    type="number"
                    onChange={event => handleWeightChange(event.target.value)}
                    label={""}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: "inline-block",
                      lineHeight: { xs: "3rem", md: "3.125rem" },
                    }}
                  >
                    {t("system.general.unit.kg")}
                  </Typography>
                </>
              ) : (
                <>
                  <TextInput
                    error={errors.includes("weight")}
                    placeholder="160"
                    value={weightLb}
                    type="number"
                    onChange={event => handleWeightChange(event.target.value)}
                    label={""}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: "inline-block",
                      lineHeight: { xs: "3rem", md: "3.125rem" },
                    }}
                  >
                    {t("system.general.unit.lb")}
                  </Typography>
                </>
              )}

              <Grid
                container
                direction="row"
                justifyContent="center"
                columnSpacing={2}
                mt={2}
              >
                <Grid item xs={4} textAlign="right">
                  <StyledButton
                    disableElevation
                    color={errors.includes("weight") ? "error" : "secondary"}
                    onClick={() => handleUnitWeightChange("metric")}
                    variant={unitWeight === "metric" ? "contained" : "outlined"}
                  >
                    {t("system.general.unit.kg")}
                  </StyledButton>
                </Grid>
                <Grid item xs={4} textAlign="left">
                  <StyledButton
                    disableElevation
                    color={errors.includes("weight") ? "error" : "secondary"}
                    onClick={() => handleUnitWeightChange("imperial")}
                    variant={
                      unitWeight === "imperial" ? "contained" : "outlined"
                    }
                  >
                    {t("system.general.unit.lb")}
                  </StyledButton>
                </Grid>
              </Grid>
            </CalcInputBox>
          </Grid>

          <Grid item xs={12} textAlign="center">
            {showError && (
              <Alert
                onClose={() => {
                  setShowError(false)
                }}
                variant="outlined"
                icon={false}
                sx={{
                  mt: theme.spacing(2),
                  backgroundColor: theme.palette.error.light,
                  color: theme.palette.error.dark,
                  fontSize: { xs: "1rem" },
                  lineHeight: { xs: "1.5rem" },
                }}
                severity="error"
              >
                <div dangerouslySetInnerHTML={{ __html: t('calculator.general.message.complete_form') }} />
              </Alert>
            )}

            <Button
              fullWidth={!upMd}
              size="large"
              variant="contained"
              onClick={handleShowResult}
              sx={{
                backgroundColor: theme.palette.secondary.dark,
                mt: { xs: 6, md: 4 },
                width: { md: 360 },
                '&:hover': {
                  backgroundColor: theme.palette.secondary.dark, // same as the non-hover color
                },
                '&.MuiButton-contained.Mui-focusVisible': {
                  backgroundColor: theme.palette.secondary.dark, // same as the non-hover color
                },
              }}
              disableElevation
            >
              {t("calculator.calories_burned.button.calculate")}
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Container id={"result-container"} style={{ display: showResult && submitted && isValid ? 'block' : 'none' }}>
          <Container sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontWeight: 600,
                textAlign: "center",
                my: theme.spacing(6),
              }}
              variant="h5"
            >
              {t("calculator.general.headline.result")}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                mt: theme.spacing(6),
              }}
            >
              {t("calculator.calories_burned.label.you_burned")}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                textAlign: "center",
                mb: theme.spacing(1),
              }}
            >
              {t("calculator.calories_burned.label.you_burned_calories", {
                calories: burnedKcal,
                kilojoule: burnedkJ,
              })}
            </Typography>
            <GatsbyImage
              style={{ borderRadius: theme.spacing(1) }}
              image={resultImage}
              alt=""
            />
            <Typography
              variant="body2"
              sx={{
                fontWeight: 700,
                textAlign: "center",
                mt: theme.spacing(1),
              }}
            >
              {`${activityName}, ${duration} min`}
            </Typography>
          </Container>
          <Container maxWidth="lg" disableGutters={!upMd} sx={{}}></Container>
        </Container>
      <Container>
        {i18next.exists('calculator_page.calories_burned.bottom.text') && (
          <Textblock mt={6}>
            <div dangerouslySetInnerHTML={{ __html: t('calculator_page.calories_burned.bottom.text') }} />
          </Textblock>
        )}
        <AdAppCounting mt={6} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "activities" } }) {
      nodes {
        name
        ext
        childImageSharp {
          gatsbyImageData(width: 180, placeholder: BLURRED)
        }
      }
    }
  }
`

export default withTrans(CalcIdealWeight)

export const Head = () => (
  <Seo title={i18next.t("calculator_page.calories_burned.title")} />
)
